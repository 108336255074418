export default (doc, startY, fontSizes, pageVariables, data) => {
    let startX = pageVariables.margin * 2
    // Title

    doc.setFont('Karla', 'normal')
    doc.setFontSize(fontSizes.SubTitleFontSize)

    startY += pageVariables.lineSpacing * 2

    let centerY = startY

    doc.text('Début de l\'événement: ' + data.dates.startDate.toLocaleString(), startX, startY)

    startY += pageVariables.lineSpacing + 2

    doc.text('Fin de l\'événement: ' + data.dates.endDate.toLocaleString(), startX, startY)

    let centerX = pageVariables.pageWidth / 2 + pageVariables.margin

    // durée

    let duration = Math.ceil((data.dates.endDate - data.dates.startDate) / (1000 * 3600 * 24))

    doc.text('Durée: ' + duration + ' jour' + (duration > 1 ? 's' : ''), centerX, centerY)

    centerY += pageVariables.lineSpacing + 2

    // Coef

    let foundCoeff = 1

    for (const [key, value] of Object.entries(data.coeffs)) {
        if (duration >= key) // days : coef
            foundCoeff = value
    }

    doc.text('Coefficient: x' + foundCoeff, centerX, centerY)

    startY += pageVariables.lineSpacing

    doc.line(pageVariables.margin, startY, pageVariables.pageWidth - pageVariables.margin, startY, 'DF')

    return startY
}