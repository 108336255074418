import gmapsInit from '@/plugins/gmaps'
import {jsPDF} from "jspdf";
import generator from "@/components/pdf/generator";
import axios from "axios";
import router from '@/router'
import {ToastProgrammatic as Toast} from 'buefy'

const QuoteProgress = {
    steps: 0,
    currentStep: 0,
    value: 0,
    running: false,
    currentValue: 0,
    cancel: false
}

const UserInfos = {
    firstName: '',
    lastName: '',
    email: '',
    sendEmail: '',
    message: '',
    organizationName: '',
    phone: '',
    addressName: '',
    addressCity: '',
    addressCountry: ''
}

const Transport = {
    allerSelection: null,
    retourSelection: null,
    // informations
    name: '',
    city: '',
    trips: 1,

    mapsCallback: {
        distance: {
            text: "",
            value: 0
        },
        duration: {
            text: "",
            value: 0
        },
        destinationAddress: '',
        originAddress: '',
        found: false
    }
}

const state = {

    // QuoteInfos
    /*
        userInfos: {
            firstName: 'Paul',
            lastName: 'meyer',
            email: 'paul.meyer@schawnndev.fr',
            sendEmail: '',
            message: '',
            organizationName: 'SchawnnDev',
            phone: '+33788490372',
            addressName: '18 avenue des tulipes',
            addressCity: '67000 Strasbourg',
            addressCountry: 'France'
        }, */
    userInfos: UserInfos,
    // QuoteTransport
    /*
    transport: {
        allerSelection: "delivery",
        retourSelection: "delivery",
        name: "kaiserallee 26",
        city: "karlsruhe",
        mapsCallback: {
            distance: {text: "112 km", value: 112493},
            duration: {text: "1 heure 21 min", value: 4888},
            status: "OK",
            found: true,
            destinationAddress: "Kaiserallee 26, 76185 Karlsruhe, Allemagne",
            originAddress: "11 Rue de l'Église, 67560 Rosheim, France"
        }
    }, */

    transport: Transport,

    // QuoteDuration

    dates: {
        startDate: new Date(),
        endDate: new Date(new Date().setHours((new Date()).getHours() + 1))
    },

    quoteToken: '',
    quoteProgress: QuoteProgress,

    existing: false,

    status: 0
}

const mutations = {

    proceedGoogleCallback(state, data) {
        state.transport.mapsCallback = data.element
        state.transport.mapsCallback.found = true
        state.transport.mapsCallback.destinationAddress = data.destination
        state.transport.mapsCallback.originAddress = data.origin
    },

    incrementStatus(state) {
        state.status++
    },

    decrementStatus(state) {
        state.status = Math.max(0, state.status - 1)
    },

    setStatus(state, value) {
        state.status = value
    },

    resetQuote(state) {
        state.transport = Transport
        state.userInfos = UserInfos
        state.dates = {
            startDate: new Date(),
            endDate: new Date(new Date().setHours((new Date()).getHours() + 1))
        }
        state.quoteToken = ''
        state.quoteProgress = QuoteProgress
        state.existing = false
    },

    setTransportType(state, {direction, value}) {
        if (direction) {
            state.transport.retourSelection = value
            return
        }

        state.transport.allerSelection = value
    },

    setQuoteToken(state, token) {
        state.quoteToken = token
    },

    setQuoteProgress(state, progress) {
        state.quoteProgress = progress
    },

    setQuoteProgressCurrentValue(state, value) {
        state.quoteProgress.currentValue = value
    },

    async incrProgressStep(state) {
        let step = Math.min(++state.quoteProgress.currentStep, state.quoteProgress.steps)
        state.quoteProgress.value = Math.ceil((step / state.quoteProgress.steps) * 100.0)

        if (state.quoteProgress.running) return

        state.quoteProgress.running = true

        while (state.quoteProgress.currentValue < state.quoteProgress.value && !state.quoteProgress.cancel) {
            state.quoteProgress.currentValue++
            await new Promise(resolve1 => setTimeout(resolve1, 50))
        }

        state.quoteProgress.cancel = false
        state.quoteProgress.running = false

    },

    loadQuote(state, quote) {
        state.existing = true
        state.status = 0
        state.quoteProgress = QuoteProgress
        state.transport = quote.transport
        state.userInfos = quote.userInfos
        state.quoteToken = quote.token
        state.dates = quote.dates
    },

    cancelProgress(state) {
        state.quoteProgress.cancel = true
        state.quoteProgress.currentStep = 0
        state.quoteProgress.currentValue = 0
        state.quoteProgress.value = 0
    }

}

const actions = {

    async fetchGoogleData({rootGetters, state, commit}, apiKey) {

        commit('data/fetchStart', {}, {root: true})

        const google = await gmapsInit(apiKey)

        return new Promise((resolve, reject) => {

            if (state.transport.name.length < 5 || state.transport.city.length < 3) {
                reject(false)
                return
            }

            if (state.transport.name.length > 256 || state.transport.city.length > 256) {
                reject(false)
                return
            }

            let startAddress = rootGetters["data/getTransport"].gmapsAddress
            let endAddress = state.transport.name + ' ' + state.transport.city

            let service = new google.maps.DistanceMatrixService()

            service.getDistanceMatrix({
                origins: [startAddress],
                destinations: [endAddress],
                travelMode: 'DRIVING'
            }, (response, status) => {

                if (status !== 'OK') {
                    reject(true)
                    return
                }

                try {

                    let element = response.rows[0].elements[0]

                    if (element === undefined || response['destinationAddresses'][0].length === 0) {
                        reject(true)
                        return
                    }

                    commit('proceedGoogleCallback', {
                        element: element,
                        destination: response['destinationAddresses'][0],
                        origin: response['originAddresses'][0]
                    })

                    resolve()

                } catch (error) {
                    reject(error)
                }

            })

        }).finally(() => commit('data/fetchEnd', {}, {root: true}))

    },

    generatePdf({commit, rootGetters, state}, goodies) {

        commit('data/fetchStart', {}, {root: true})

        let doc = new jsPDF('p', 'pt');

        return new Promise((resolve, reject) => {

            try {

                doc = generator(doc, {
                    articles: rootGetters["cart/getItems"],
                    userInfos: state.userInfos,
                    dates: state.dates,
                    coeffs: rootGetters["data/getDuration"].coeffs,
                    transport: state.transport,
                    transportData: rootGetters["data/getTransport"],
                    token: rootGetters["quote/getQuoteToken"],
                    cautionPrice: rootGetters["cart/getCautionPrice"],
                    goodies: goodies
                })

                resolve(doc)

            } catch (e) {
                reject(undefined)
            }

        }).finally(() => commit('data/fetchEnd', {}, {root: true}))
    },

    generateCode({commit, rootGetters}, token) {

        let params = {
            captchaToken: token,
        }
        let existing = rootGetters['quote/isQuoteExisting']
        if (existing) params['existing'] = true

        return axios.post('/token', params).then(({data}) => {
            if (!existing)
            {
                commit('setQuoteToken', data.token)
            }

            commit('incrementStatus')
        })

    },

    sendEmail({commit, rootGetters}, file) {
        let token = rootGetters['quote/getQuoteToken']
        let existing = rootGetters['quote/isQuoteExisting']
        return axios.post('/send', {
            token: token,
            existing: existing,
            pdf: file,
            cart: JSON.stringify({
                items: rootGetters['cart/getItems'],
                userHasMemberCard: rootGetters['cart/hasMemberCard']
            }),
            quote: JSON.stringify({
                userInfos: rootGetters['quote/getUserInfos'],
                transport: rootGetters['quote/getTransport'],
                dates: rootGetters['quote/getDates']
            })
        }).then(() => {
            commit('cancelProgress')
            commit('incrementStatus')

            if (existing) {
                Toast.open('Votre devis #' + token + ' a bien été modifié.')
            }

            commit('resetQuote')
            commit('cart/resetCart', null, {root: true})
        })

    },

    // eslint-disable-next-line no-unused-vars
    load({commit}, tokens) {

        return axios.get('/load', {
            params: tokens
        }).then(({data}) => {

            let quote = JSON.parse(data.quote)

            quote.dates = {
                startDate: new Date(quote.dates.startDate),
                endDate: new Date(quote.dates.endDate)
            }

            commit('loadQuote', quote)
            commit('cart/load', JSON.parse(data.cart), {root: true})
            commit('cart/save', null, {root: true})
            commit('setQuoteToken', data.token)

            Toast.open('Votre devis #' + data.token + ' a bien été chargé.')
            router.push('/')
        })

    },

}

const getters = {
    getStatus: state => state.status,
    getTransport: state => state.transport,
    getDates: state => state.dates,
    getUserInfos: state => state.userInfos,
    getQuoteToken: state => state.quoteToken,
    getQuoteProgress: state => state.quoteProgress,
    isQuoteExisting: state => state.existing
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}