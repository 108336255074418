function generateArticleArray(article, prices) {
    let result = []
    result[0] = article.name

    let options = []
    let total = 0

    for (let i = 0; i < article.options.length; i++) {
        let option = article.options[i]

        if (!option._selected_) continue;

        let optionPrice = option.fixedPrice ? option.price : article._amount_ * option.price

        total += optionPrice

        options.push('- ' + option.name + ' (' + optionPrice.toFixed(2) + ' €)')
    }

    result[1] = options.length === 0 ? 'Aucune' : options.join('\n')

    // quantity
    result[2] = article.amountText.replace('{amount}', article._amount_)

    let caution = article.fixedCaution ? article.caution : article._amount_ * article.caution

    // caution
    result[3] = caution.toFixed(2) + ' €'

    prices.caution += caution
    prices.options += total
    // Sous-Total
    total += article._amount_ * article.price
    result[4] = total.toFixed(2) + ' €'

    prices.total += total

    return result
}


// eslint-disable-next-line no-unused-vars
export default (doc, startY, fontSizes, pageVariables, data) => {

    let convertedData = []
    let prices = {
        caution: 0,
        options: 0,
        total: 0
    }

    for (let i = 0; i < data.articles.length; i++) {
        convertedData.push(generateArticleArray(data.articles[i], prices))
    }

    convertedData.push(
        ['', '', '', prices.caution.toFixed(2) + ' €',
            prices.total.toFixed(2) + ' €']
    )

    doc.autoTable({
        startY: startY + 20,
        styles: {
            font: 'Karla'
        },
        head: [['Article', 'Options (prix)', 'Quantité', 'Caution', 'Sous-total']],
        body: convertedData,
    })

    startY = doc.lastAutoTable.finalY + 20

    doc.setDrawColor('#000000')

    doc.line(pageVariables.margin, startY, pageVariables.pageWidth - pageVariables.margin, startY, 'DF')

    if(startY >= pageVariables.pageHeight)
    {
        doc.addPage()
        startY = pageVariables.margin
    }

    return startY
}