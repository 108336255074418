import Vue from 'vue'
import Vuex from 'vuex'

import cart from './cart.module'
import data from './data.module'
import quote from './quote.module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    cart: cart,
    data: data,
    quote: quote
  },
})

export default store