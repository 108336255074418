export default (doc, startY, fontSizes, pageVariables, data) => {
    let startX = pageVariables.margin * 1.5
    // Title

    doc.setFont('Karla', 'normal')
    doc.setFontSize(fontSizes.NormalFontSize)

    startY += pageVariables.lineSpacing * 2

    if(!data.goodies)
    {
        doc.text('La caution de ' + data.cautionPrice.toFixed(2) + ' € est déposée par le demandeur par chèque à Fanabriques lors du retrait/remise du matériel.', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('Elle est rendue après contrôle sans manque et après réception du règlement de la facture. La caution n\'est pas', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('demandée lorsque l\'animation est gérée par Fanabriques (si un animateur Fanabriques fait l\'animation, emmène', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('et repart avec le matériel.)', startX, startY)

        startY += pageVariables.lineSpacing * 2

        doc.text('Un devis définitif avec indication de la disponibilité du matériel vous sera envoyé sous 2 jours ouvrés.', startX, startY)
    } else {

        doc.text('La validation du bon de commande vous sera envoyée par mail sous 2 jours ouvrés.', startX, startY)

        startY += pageVariables.lineSpacing * 2

        if(data.transport.allerSelection === 'delivery')
        {
            doc.text('Vous avez souhaité un envoi par Mondial Relay.', startX, startY)
        } else {
            doc.text('Vous avez souhaité la récupération sur place.', startX, startY)
        }

        startY += pageVariables.lineSpacing * 2

        if(startY >= pageVariables.pageHeight)
        {
            doc.addPage()
            startY = pageVariables.margin
        }

        const relayTitle = 'Option Envoi Mondial Relay';
        doc.text(relayTitle, startX, startY)
        doc.line(startX, startY + 2, startX + doc.getTextWidth(relayTitle), startY + 2)

        startY += pageVariables.lineSpacing

        doc.text('Dans le mail de validation nous vous indiquerons le poids du colis.', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('Vous payez la commande en suivant les indications ci-dessous, et achetez l\'étiquette Mondial Relay.', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('Une fois l\'étiquette reçue, vous nous l\'envoyez en pièce-jointe en réponse au mail de validation.', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('Nous vous enverrons la commande sous 48 heures après réception du paiement et de l\'étiquette.', startX, startY)

        startY += pageVariables.lineSpacing * 2

        if(startY >= pageVariables.pageHeight)
        {
            doc.addPage()
            startY = pageVariables.margin
        }

        const recupTitle = 'Option Récupération de la commande à Rosheim';
        doc.text(recupTitle, startX, startY)
        doc.line(startX, startY + 2, startX + doc.getTextWidth(recupTitle), startY + 2)

        startY += pageVariables.lineSpacing

        doc.text('Nous vous indiquerons les horaires dans le mail de validation.', startX, startY)

        startY += pageVariables.lineSpacing * 2

        doc.text('Paiement par chèque à: Fanabriques - 14 Avenue Foch - 67560 ROSHEIM', startX, startY)

        startY += pageVariables.lineSpacing * 2

        doc.text('Paiement par virement: Bénéficiaire: Fanabriques, BIC: CCBPFRPPMTZ', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('IBAN : FR76 1470 7501 8070 1954 7074 072', startX, startY)

        startY += pageVariables.lineSpacing * 2

        if(startY >= pageVariables.pageHeight)
        {
            doc.addPage()
            startY = pageVariables.margin
        }

        doc.text('Paiement par PayPal: postmaster@fanabriques.fr', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('ATTENTION: uniquement paiement entre proches, si vous n\'optez pas pour la paiement entre proches,', startX, startY)

        startY += pageVariables.lineSpacing

        doc.text('nous annulerons votre paiement (remboursement sous déduction des frais d\'opération).', startX, startY)
    }

    return startY
}
