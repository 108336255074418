// eslint-disable-next-line no-unused-vars

function getTransportName(val, goodies) {
    if (val === 'delivery' && !goodies)
        return 'Livraison'
    if (val === 'delivery' && goodies)
        return 'Envoi par mondial relay'
    if (val === 'anim')
        return 'Animateur'
    return 'Retrait sur place'
}

export default (doc, startY, fontSizes, pageVariables, data) => {
    let startX = pageVariables.margin * 1.5
    // Title

    doc.setFont('Karla', 'normal')
    doc.setFontSize(fontSizes.NormalFontSize)

    startY += pageVariables.lineSpacing * 2

    let centerY = startY
    let centerX = pageVariables.pageWidth / 2 + pageVariables.margin

    doc.text((data.goodies ? 'Récupération commande: ' : 'Transport aller: ') + getTransportName(data.transport.allerSelection, data.goodies), startX, startY)

    startY += pageVariables.lineSpacing + 2

    if (!data.goodies) {
        if (data.transport.allerSelection === 'delivery' || data.transport.allerSelection === 'anim') {
            let text = '-> ' + data.transport.mapsCallback.destinationAddress

            if (doc.getTextWidth(text) + startX >= centerX) {
                let first = text.substring(0, Math.ceil(text.length / 1.5)); // Gets the first part
                let last = text.substring(Math.ceil(text.length / 1.5) + 1);  // Gets the text part

                doc.text(first, startX, startY)

                startY += pageVariables.lineSpacing

                doc.text(last, startX + doc.getTextWidth('-> '), startY)

            } else {
                doc.text(text, startX, startY)
            }

            startY += pageVariables.lineSpacing + 2

            doc.text('Distance (durée): ' + data.transport.mapsCallback.distance.text + ' (x2) (' + data.transport.mapsCallback.duration.text + ')', startX, startY)

            startY += pageVariables.lineSpacing + 2

            let sousTotal = Math.ceil(((data.transportData.kmPrice / 1000) * data.transport.mapsCallback.distance.value + data.transportData.hourPrice * (data.transport.mapsCallback.duration.value / 3600)) * 2)


            if (data.transport.allerSelection === 'anim') {
                doc.text('Nombre d\'allez-retours: ' + data.transport.trips, startX, startY)
                startY += pageVariables.lineSpacing + 2
                doc.text('Sous-total: ' + (data.transport.trips * sousTotal).toFixed(2) + ' €', startX, startY)
            } else {
                doc.text('Sous-total: ' + sousTotal.toFixed(2) + ' €', startX, startY)
            }

            startY += pageVariables.lineSpacing + 2

        }

        doc.text('Transport retour: ' + getTransportName(data.transport.retourSelection, false), centerX, centerY)

        centerY += pageVariables.lineSpacing + 2

        if (data.transport.retourSelection === 'delivery') {
            doc.text('-> ' + data.transport.mapsCallback.originAddress, centerX, centerY)

            centerY += pageVariables.lineSpacing + 2

            doc.text('Distance (durée): ' + data.transport.mapsCallback.distance.text + ' (x2) (' + data.transport.mapsCallback.duration.text + ')', centerX, centerY)

            centerY += pageVariables.lineSpacing + 2

            let sousTotal = Math.ceil(((data.transportData.kmPrice / 1000) * data.transport.mapsCallback.distance.value + data.transportData.hourPrice * (data.transport.mapsCallback.duration.value / 3600)) * 2)

            doc.text('Sous-total: ' + sousTotal.toFixed(2) + ' €', centerX, centerY)

            centerY += pageVariables.lineSpacing + 2

        }
    }

    startY = Math.max(startY, centerY)

    // durée

    doc.line(pageVariables.margin, startY, pageVariables.pageWidth - pageVariables.margin, startY, 'DF')

    if(startY >= pageVariables.pageHeight)
    {
        doc.addPage()
        startY = pageVariables.margin
    }

    return startY
}