<template>
  <div>

    <div class="title">
      Bienvenue sur le catalogue de Fanabriques
    </div>

    <hr class="solid">

    <h3>Découvrez nos offres à travers différentes catégories, ajoutez les au panier, et générez votre devis estimatif...</h3>

    <br/>

    <div class="brick b-red" style="padding: 15px; margin: 10px">
      <p class="title" style="color: white">Boutique Goodies</p>
      <b-button tag="router-link" :to="'/categories/' + homeCategories[5]" type="is-info is-light">Accéder à la catégorie</b-button>
    </div>

    <div class="home">
      <div class="brick b-purple">
        <p class="title">Prestation Mosaïques</p>
        <p class="subtitle">De 7 à 87 ans (ou +) !</p>
        <figure class="image is-4by3">
          <router-link :to="'/categories/' + homeCategories[1]"><img src="@/assets/siteboutmos.png" alt="Prestation Mosaïque">
          </router-link>
        </figure>
      </div>

      <div class="brick b-blue">
        <p class="title">Location de Vracs</p>
        <p class="subtitle">Laissez faire votre imagination</p>
        <figure class="image is-4by3">
          <router-link :to="'/categories/' + homeCategories[4]"><img src="@/assets/siteboutvrac.png" alt="Prestation location de vracs"></router-link>
        </figure>
      </div>

      <div class="brick b-green">
        <p class="title">Prêt Kits Animation</p>
        <p class="subtitle">Kits prêts à l'emploi</p>
        <figure class="image is-4by3">
          <router-link :to="'/categories/' + homeCategories[7]"><img src="@/assets/siteboutkit.png" alt="Prestation location de kits d'animation"></router-link>
        </figure>
      </div>

      <div class="brick b-amber">
        <p class="title">Prestation Animateur</p>
        <p class="subtitle">Faites appel à nos compétences</p>
        <figure class="image is-4by3">
          <router-link :to="'/categories/' + homeCategories[6]"><img src="@/assets/siteboutani.png" alt="Prestation animateur">
          </router-link>
        </figure>
      </div>

    </div>



  </div>
</template>

<script>
// @ is an alias to /src

import {mapState} from "vuex";

export default {
  name: 'Home',
  computed: {
    ...mapState("data", ["homeCategories"]),
  }
}
</script>

<style scoped>
.home {
  display: flex;
  justify-content: space-between;
}

.home .brick {
  width: 80%;
  margin: 10px;
  border-radius: 5px;
  padding: 15px;
}

.home .brick .title {
  color: white;
}

.home .brick .subtitle {
  color: white;
}

.home .brick figure .rounded {
  border-radius: 5px;
}
</style>
