<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/fanabriques-logo.jpg" alt="Logo FanaBriques">
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Accueil
      </b-navbar-item>

      <b-navbar-dropdown label="Catégories">
        <b-navbar-item tag="router-link" :to="{ path: '/categories'}">Aperçu</b-navbar-item>
        <hr class="dropdown-divider">
        <b-navbar-item v-for="(category, index) in categories" :key="index" tag="router-link" :to="{ path: ('/categories/' + category.slug) }">
          {{category.name}}
        </b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-dropdown label="Devis">
        <b-navbar-item tag="router-link" :to="{ path: '/quote/load'}">Charger un devis</b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-item tag="router-link" :to="{ path: '/about' }">
        A propos
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button tag="router-link" to="/cart" type="is-warning" icon-left="basket">
            <span>Panier : {{itemsCount === 0 ? 'Vide' : itemsCount + ' article' + (itemsCount > 1 ? 's' : '')}}</span>
          </b-button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Navbar",
  computed: {
    ...mapGetters("data", ["getCategories"]),
    ...mapGetters("cart", ["itemsCount"]),

    categories: {
      get() {
        let cat = this.getCategories
        return cat === undefined ? [] : cat.sort((a, b) => a.name.localeCompare(b.name));
      },
    }
  },

}
</script>

<style scoped>
</style>