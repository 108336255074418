export default (doc, startY, fontSizes, pageVariables, userData) => {
    let startX = pageVariables.margin * 2
    // Title

    doc.setFont('Karla', 'normal')
    doc.setFontSize(fontSizes.SubTitleFontSize)

    startY += pageVariables.lineSpacing * 2

    let titleY = startY

    doc.text('Destinataire :', startX, startY)

    startY += pageVariables.lineSpacing + 5
    startX += 5

    // address
    if(userData.organizationName.length > 1)
    {
        doc.text(userData.organizationName, startX, startY)
        startY += pageVariables.lineSpacing
    }

    doc.text(userData.addressName, startX, startY)
    startY += pageVariables.lineSpacing
    doc.text(userData.addressCity, startX, startY)
    startY += pageVariables.lineSpacing
    doc.text(userData.addressCountry, startX, startY)


    startX = pageVariables.pageWidth / 2

    // contact
    doc.text('Contact client :', startX, titleY)

    titleY += pageVariables.lineSpacing + 5
    startX += 5

    // address
    doc.text(userData.firstName + ' ' + userData.lastName, startX, titleY)
    titleY += pageVariables.lineSpacing + 2
    doc.text(userData.email, startX, titleY)

    if(userData.phone.length > 1)
    {
        titleY += pageVariables.lineSpacing + 2
        doc.text(userData.phone, startX, titleY)
    }

    startY += pageVariables.lineSpacing

    doc.line(pageVariables.margin, startY, pageVariables.pageWidth - pageVariables.margin, startY, 'DF')

    return startY
}