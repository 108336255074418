const state = {
    items: [],
    userHasMemberCard: false
}

const mutations = {

    addItem(state, item) {
        state.items.push(item)
    },

    removeItem(state, item) {
        state.items = state.items.filter(t => t._id_ !== item._id_)
    },

    clearItems(state) {
        state.items = []
    },

    setHasMemberCard(state, bool) {
        state.userHasMemberCard = bool
    },

    save(state) {
        window.localStorage.setItem('cart', JSON.stringify({
            items: state.items,
            userHasMemberCard: state.userHasMemberCard
        }))
    },

    load(state, obj) {
        state.items = obj.items
        state.userHasMemberCard = obj.userHasMemberCard
    },

    resetCart(state) {
        state.items = []
        state.userHasMemberCard = false
        window.localStorage.removeItem('cart')
    }
}

const actions = {

    addToCart({commit}, item) {
        commit("addItem", item);
        commit("save")
    },

    removeFromCart({commit}, item) {
        commit("removeItem", item)
        commit("save")
    },

    clearCart({commit}) {
        commit("clearItems")
        commit("save")
    },

    changeMemberCardStatus({commit}, bool) {
        commit("setHasMemberCard", bool)
        commit("save")
    },

    loadCart({commit}) {
        let cart = window.localStorage.getItem('cart')
        if (cart == null) return
        cart = JSON.parse(cart)
        commit("load", cart)
    }

}

const getters = {
    itemsCount: state => state.items.length,
    getItems: state => state.items,

    needsMemberCard(state) {
        for (let i = 0; i < state.items.length; i++) {
            let item = state.items[i]
            if (!item.memberCard) continue
            return true
        }
        return false
    },

    hasMemberCard(state) {
        for (let i = 0; i < state.items.length; i++) {
            let item = state.items[i]
            if (!item._id_.startsWith('member_card')) continue
            return true
        }
        return false
    },

    getCautionPrice(state) {
        let total = 0;

        for (let i = 0; i < state.items.length; i++) {
            let item = state.items[i]
            total += item.fixedCaution ? item.caution : item._amount_ * item.caution
        }

        return total
    },

    getTotalPrice(state) {

        let total = 0;

        for (let i = 0; i < state.items.length; i++) {
            let item = state.items[i]
            let amount = item._amount_

            total += amount * item.price

            for (let i = 0; i < item.options.length; i++) {
                let option = item.options[i]

                if (!option._selected_) continue;

                total += option.fixedPrice ? option.price : amount * option.price
            }
        }

        return total
    },

    getFullPriceByType: (state) => (type) => {
        let total = 0;

        for (let i = 0; i < state.items.length; i++) {
            let item = state.items[i]
            let amount = item._amount_

            if (item.type === type) total += amount * item.price

            for (let i = 0; i < item.options.length; i++) {
                let option = item.options[i]

                if (!option._selected_) continue

                if (option.type === type) total += option.fixedPrice ? option.price : amount * option.price
            }
        }

        return total
    },

    getTotalPriceByType: (state) => (type) => {
        let total = 0;

        for (let i = 0; i < state.items.length; i++) {
            let item = state.items[i]
            let amount = item._amount_

            if (item.type !== type) continue

            total += amount * item.price

            for (let i = 0; i < item.options.length; i++) {
                let option = item.options[i]

                if (!option._selected_ || option.type !== type) continue

                total += option.fixedPrice ? option.price : amount * option.price
            }
        }

        return total
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}