import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import store from './store'
import router from './router'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Buefy);

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL || ""
axios.defaults.headers.get['Content-Type'] = 'application/json; charset=utf-8'

Vue.use(VueAxios, axios);

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
