import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";
import { NotificationProgrammatic as Notification } from 'buefy'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
      path: '/bretzel',
      name: 'PersonalData',
      component: () => import('../views/PersonalData')
    },
    {
      path: '/sitemap',
      name: 'SiteMap',
      component: () => import('../views/SiteMap')
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import('../views/Cart')
    },
    {
        path: '/quote',
        name: 'Quote',
        component: () => import('../views/Quote')
    },
    {
        path: '/quote/load',
        name: 'QuoteLoad',
        component: () => import('../views/QuoteLoad')
    },
    {
        path: '/quote/load/:token',
        name: 'QuoteLoadToken',
        component: () => import('../views/QuoteLoad')
    },
    {
        path: '/categories',
        name: 'Categories',
        component: () => import('../views/Categories')
    },
    {
        path: '/categories/:slug',
        name: 'Category',
        component: () => import('../views/Category')
    },
    {
        path: '/categories/:category/:slug',
        name: 'Article',
        component: () => import('../views/Article')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/404')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes
})

router.beforeEach((to, from, next) => {

    if (store.getters["data/isLoaded"]) {
        next()
        return;
    }

    store.commit('data/fetchStart')

    Promise.all([
        store.dispatch("cart/loadCart"),
        store.dispatch("data/load")
    ]).then(() => {
        store.commit('data/fetchEnd')
        next()
    }).catch(() => {
        store.commit('data/fetchEnd')
        Notification.open({
            message: `Impossible de charger les informations necessaires.<br>Si le problème persiste, veuillez contacter le webmaster.`,
            position: 'is-top',
            type: 'is-danger',
            hasIcon: true
        })
        next({name: 'About'})
    })
})

export default router
