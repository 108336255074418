import axios from "axios";
import DOMPurify from 'dompurify';

const state = {
    data: {},
    loading: true,
    loaded: false,
    siteKey: process.env.VUE_APP_CAPTCHA_KEY || "",

    // json

    countries: [],
    homeCategories: {}
}

const mutations = {

    fetchStart(state) {
        state.loading = true;
    },

    fetchEnd(state) {
        state.loading = false;
        state.loaded = true;
    },

    fetchSuccess(state, data) {
        let slugify = require('slugify')

        for (let i = 0; i < data.categories.length; i++) {

            let category = data.categories[i]

            category.slug = slugify(category.name.toLowerCase())
            state.homeCategories[category.id] = category.slug

            for (let j = 0; j < category.articles.length; j++) {
                let article = category.articles[j]
                article.slug = slugify(article.name.toLowerCase())
                article.price = parseFloat(article.price)
                article.caution = parseFloat(article.caution)
                article.amounts = JSON.parse(article.amounts)
                article.description = DOMPurify.sanitize(article.description)
                article.techInfos = DOMPurify.sanitize(article.techInfos)
                article.fixedAmounts = parseInt(article.fixedAmounts)
                article.fixedCaution = parseInt(article.fixedCaution)
                article.memberCard = parseInt(article.memberCard)
                article.minAge = parseInt(article.minAge)
                article.stock = parseInt(article.stock)

                for (let k = 0; k < article.options.length; k++) {
                    let option = article.options[k]
                    option.price = parseFloat(option.price)
                    option.caution = parseFloat(option.caution)
                    option.fixedPrice = parseInt(option.fixedPrice)
                }
            }

            category.items = category.articles
            delete category.articles

        }

        let memberCard = {}

        Object.keys(data.memberCard).forEach(key => {
            let newKey = key.replace('memberCard', '')
            newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1)
            memberCard[newKey] = data.memberCard[key]
        })


        memberCard.price = parseFloat(memberCard.price)
        memberCard.caution = parseFloat(memberCard.caution)


        data.memberCard = memberCard
        data.transport.kmPrice = parseFloat(data.transport.kmPrice)
        data.transport.hourPrice = parseFloat(data.transport.hourPrice)

        state.data = data;

        /**
         *  traiter ici
         */
    },

    setCountries(state, data) {
        state.countries = data
    },

    setSiteKey(state, key) {
        state.siteKey = key
    },

}

const actions = {

    load({commit}) {

        return new Promise(((resolve, reject) => axios.get('').then(({data}) => {
            data.categories = JSON.parse(data.categories)
            commit('fetchSuccess', data)
            resolve()
        }).catch(error => {
            reject(error)
            throw new Error(error)
        })))


    },

    // eslint-disable-next-line no-unused-vars
    loadCountries({commit}) {
        commit('setCountries', ['France', 'Allemagne', 'Belgique', 'Suisse', 'Italie', 'Luxembourg'])
    }

}

const getters = {
    isLoaded: state => state.loaded,
    getMemberCard: state => state.data.memberCard,
    getTransport: state => state.data.transport,
    getDuration: state => state.data.duration,
    isLoading: state => state.loading,
    getCategories: state => state.data.categories,
    getCountries: state => state.countries,
    getCategory: (state) => (slug) => state.data.categories.find(obj => obj.slug === slug),
    getCategoryById: (state) => (id) => state.data.categories.find(obj => obj.id === id),
    getItem: (state) => (slug) => {
        for (let i = 0; i < state.data.categories.length; i++) {
            let found = state.data.categories[i].items.find(obj => obj.slug === slug)

            if (found === undefined)
                continue

            return found
        }

        return undefined
    },
    getItemCategory: (state) => (item) => {

        for (let i = 0; i < state.data.categories.length; i++) {
            let found = state.data.categories[i]

            if (found.items.find(obj => obj.slug === item.slug) === undefined)
                continue

            return found
        }

        return undefined
    },

    getCaptchaSiteKey: (state) => state.siteKey

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}