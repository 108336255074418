export default (doc, startY, fontSizes, pageVariables, data) => {
    let startX = pageVariables.margin

    // logo
    let logo = new Image()
    logo.src = require('@/assets/fanabriques-logo.jpg')
    let logoWidth = 120, logoHeight = 60

    let logoX = pageVariables.pageWidth - startX - logoWidth - 10
    let logoY = startY

    doc.addImage(logo, 'jpeg', logoX, logoY, logoWidth, logoHeight)

    logoX += 10
    logoY += logoHeight + pageVariables.lineSpacing + 5
    doc.setFontSize(fontSizes.SubTitleFontSize)

    // under logo
    doc.text('Date: ' + new Date().toLocaleDateString(), logoX, logoY)

    logoY += pageVariables.lineSpacing

    doc.text('Code réf.: ' + data.token, logoX, logoY)

    // Title

    doc.setFont('Karla', 'bold')
    doc.setFontSize(data.goodies ? 34 : 38)

    doc.text(data.goodies ? 'Commande goodies' : 'Devis estimatif', startX, startY + 30)

    doc.setFont('Karla', 'normal')
    doc.setFontSize(fontSizes.NormalFontSize)

    startY += pageVariables.lineSpacing * 2 + 30

    // address

    doc.text('Association Fanabriques', startX, startY)
    startY += pageVariables.lineSpacing
    doc.text('14 avenue Foch', startX, startY)
    startY += pageVariables.lineSpacing
    doc.text('67560 Rosheim', startX, startY)
    startY += pageVariables.lineSpacing
    doc.text('www.fanabriques.fr', startX, startY)
    startY += pageVariables.lineSpacing
    doc.text('postmaster@fanabriques.fr', startX, startY)

    startY += pageVariables.lineSpacing * 1.5

    doc.line(startX, startY, pageVariables.pageWidth - startX, startY, 'DF')

    return startY
}