import header from "@/components/pdf/header";
import customerAddress from "@/components/pdf/customerAddress";
import eventInfos from "@/components/pdf/eventInfos";
import articleTable from "@/components/pdf/articleTable";
import transportTable from "@/components/pdf/transportTable";
import footer from "@/components/pdf/footer";

import 'jspdf-autotable'
import total from "@/components/pdf/total";

export default (doc, data) => {

    const fontSizes = {
        TitleFontSize: 14,
        SubTitleFontSize: 12,
        NormalFontSize: 10,
        SmallFontSize: 9
    };

    const pageVariables = {
        // eslint-disable-next-line no-unused-vars
        pageHeight: doc.internal.pageSize.height,
        pageWidth: doc.internal.pageSize.width,
        lineSpacing: 12,
        margin: 32
    }

    // eslint-disable-next-line no-unused-vars
    let startY = pageVariables.margin; // bit more then 45mm

    doc.addFont(require('@/assets/fonts/Karla-Regular.ttf'), 'Karla', 'normal')
    doc.addFont(require('@/assets/fonts/Karla-Bold.ttf'), 'Karla', 'bold')
    doc.addFont(require('@/assets/fonts/Karla-ExtraBold.ttf'), 'Karla', 'extra-bold')

    doc.setFont('Karla')
    doc.setFontSize(fontSizes.NormalFontSize)

    startY = header(doc, startY, fontSizes, pageVariables, data)

    startY = customerAddress(doc, startY, fontSizes, pageVariables, data.userInfos)

    if(!data.goodies)
    {
        startY = eventInfos(doc, startY, fontSizes, pageVariables, data)
    }

    startY = articleTable(doc, startY, fontSizes, pageVariables, data)

    startY = transportTable(doc, startY, fontSizes, pageVariables, data)

    startY = total(doc, startY, fontSizes, pageVariables, data)

    startY = footer(doc, startY, fontSizes, pageVariables, data)

    return doc
}