import store from '../../store'

// eslint-disable-next-line no-unused-vars
export default (doc, startY, fontSizes, pageVariables, data) => {

    // Coef

    let duration = Math.ceil((data.dates.endDate - data.dates.startDate) / (1000 * 3600 * 24))

    let foundCoeff = 1

    for (const [key, value] of Object.entries(data.coeffs)) {
        if (duration >= key) // days : coef
            foundCoeff = value
    }


    // eslint-disable-next-line no-unused-vars
    let prices = {
        totalCoef: store.getters['cart/getTotalPriceByType']('materiel') * foundCoeff,
        totalNoCoef: store.getters['cart/getFullPriceByType']('article'),
        totalTransport: 0,
        totalCaution: store.getters['cart/getCautionPrice'],
        total: 0
    }

    let transportCount = 0

    if (data.transport.retourSelection === 'delivery') transportCount++
    if (data.transport.allerSelection === 'delivery') transportCount++

    if (data.transport.allerSelection === 'anim') transportCount += data.transport.trips

    prices.totalTransport = transportCount * Math.ceil(((data.transportData.kmPrice / 1000) * data.transport.mapsCallback.distance.value
        + data.transportData.hourPrice * (data.transport.mapsCallback.duration.value / 3600)) * 2)

    prices.total = prices.totalCoef + prices.totalNoCoef + prices.totalTransport

    let bodyData = []

    if (!data.goodies) {
        bodyData.push(['Total des éléments coefficientés', prices.totalCoef.toFixed(2) + ' €'])
        bodyData.push(['Total des éléments non coefficientés', prices.totalNoCoef.toFixed(2) + ' €'])
        bodyData.push(['Total transport', prices.totalTransport.toFixed(2) + ' €'])
    }

    bodyData.push(['Total', prices.total.toFixed(2) + ' €'])

    doc.autoTable({
        startY: startY + 20,
        styles: {
            font: 'Karla'
        },

        head: [['description', 'value']],

        body: bodyData,

        showHead: false,

        margin: {left: pageVariables.pageWidth / 2},

        columnStyles: {
            0: {fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold', cellWidth: 'wrap'},
            1: {fontStyle: 'bold'}
        },
    })

    startY = doc.lastAutoTable.finalY + 20

    const text = 'L\'association est exonérée de TVA.'

    doc.text(text, pageVariables.pageWidth - pageVariables.margin - doc.getTextWidth(text), startY)

    startY += pageVariables.lineSpacing * 2

    doc.setDrawColor('#000000')

    doc.line(pageVariables.margin, startY, pageVariables.pageWidth - pageVariables.margin, startY, 'DF')

    if(startY >= pageVariables.pageHeight)
    {
        doc.addPage()
        startY = pageVariables.margin
    }

    return startY
}